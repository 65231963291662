/**
 * Created by mor on 04/12/2018.
 *
 * Decor Advanced Web Solutions
 * www.decor-d.com
 *
 * File description:
 */
import React from 'react';
import PropTypes from 'prop-types';
import ValidatedInput from "../validated-input";
import {ValidatedField, ValidatedLabel} from "./shapes";
import Dropdown from 'react-toolbox/lib/dropdown';

class ValidatedForm extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            data: this.buildSchema(),
            valid: true,
            selectedOption:'',
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.valid !== this.state.valid) {
            this.props.onValidChange(this.state.valid);
        }
    }

    componentDidMount() {
        if (!this.props.isValidOnStart) {
            this.setState({
                valid: this.validateFormScheme(this.state.data)
            });
        }
    }

    /**
     * validate input and update state
     * @param value
     * @param name
     */
    handleFieldChange = async (value, { target: {name} }) => {
        if(name === 'email'){
            await this.checkEmailForEmailDomain(value, name); 
        }
   
        const nextState = {
            ...this.state.data,
            [name]: {
                ...this.state.data[name],
                value
            }
        };
        if('company' in this.state.data && (Object.keys(this.props.companies).length === 0 || this.props.companies.length < 2) ){
            delete nextState.company
            this.props.setOptione('')
        } 
        if (!('company' in this.state.data) && this.props.companies && this.props.companies.length >= 2){
            nextState['company'] = {error: '', value: ''}
        }
        if(this.state.data[name].error != '' ){
            
            const error = this.props.fields[name].validator(value);
            
            this.state.data[name].error = error
        }
        const valid = this.validateFormScheme(nextState);

        this.setState({
            data: nextState,
            valid
        });

        if(this.props.handleFieldValueChange){
            this.props.handleFieldValueChange(name, value, valid)
        }
    };

    async checkEmailForEmailDomain(value, name){
       if(this.props.fields[name].validator(value) === '' && this.props.checkEmailDomain){
            await this.props.checkEmailDomain(value)
       }
    }
    /**
     *
     * @param func
     * @param e
     */
    handleMetaBlur = (func, e) => {
        this.handleBlur(e);
        func.apply(this, [this.state.data]);
    };

    handleBlur = ({ target: {name, value}}) => {

        const error = this.props.fields[name].validator(value);
        const nextState = {
            ...this.state.data,
            [name]: {
                ...this.state.data[name],
                error
            }
        };

        this.setState({
            data: nextState,
            ...this.state.valid
        })

    };

    /**
     * builds state scheme
     * @returns {{}}
     */
    buildSchema = () => Object.entries(this.props.fields).reduce((acc, entry) => {
        const name = entry[0], meta = entry[1];

        if (name !== 'company'){
            acc[name] = {
                error: ( !this.props.isValidOnStart && meta.defaultValue && meta.validator) ? meta.validator(meta.defaultValue) : '',
                value: meta.defaultValue ? meta.defaultValue : ''
            };
        }

        return acc;
    }, {});

    validateFormScheme = (state) => Object.entries(state).reduce((valid, entry) => {
        return valid &&
            ((this.props.fields[entry[0]].validator) ? this.props.fields[entry[0]].validator(entry[1].value) === '' : true)
    }, true);

    handleDropdownChange = (value,{ target: {name} }) => {
        this.setState({selectedOption:value})
        this.handleFieldChange(value,{ 'target':{'name':name} } )
        let company_select = {};
        for (let i = 0; i < this.props.companies.length; i++) {
            if(this.props.companies[i].name === value){
                company_select = this.props.companies[i];
            }
        }
        this.props.setOptione(company_select)
    }
    /**
     * render the fields
     * @returns {any[]}
     */
    renderFields = () => Object.entries(this.props.fields).map(entry => {
        const name = entry[0], meta = entry[1];
        
        if (name === "company"){
            const options = []
            const companies = this.props.companies;
            if(companies !== undefined && Object.keys(companies).length >=2){
                this.props.companies.map((company) => options.push({ value: company.name, label: company.name }))

                return  <div
                className={`validated-input-container ${meta.klass ? meta.klass : ''} ${meta.toolTip ? 'with-tooltip' : ''}`}
            >
                <Dropdown
                        source={options}
                        name={name}
                        onChange={this.handleDropdownChange}
                        value={this.state.selectedOption}
                        type={meta.type}
                        label={meta.label}
                        required={meta.required}
                        disabled={meta.disabled}
                        className={meta.klass}
                        defaultValue={meta.defaultValue}
                        key={name}
                    />
                </div>             
            }
        }
        else return (

            meta.type === 'label' ?
                <label key={name}>{meta.label}</label> :
                <ValidatedInput type={meta.type}
                                label={meta.label}
                                required={meta.required}
                                disabled={meta.disabled}
                                className={meta.klass}
                                toolTip={meta.toolTip}
                                defaultValue={meta.defaultValue}
                                name={name}
                                key={name}
                                error={this.state.data[name].error}
                                value={this.state.data[name].value}
                                onChange={this.handleFieldChange}
                                hint={meta.hint}
                                onBlur={meta.onBlur ? (e) => this.handleMetaBlur(meta.onBlur, e) : this.handleBlur}
                                validateIcon={meta.validateIcon}
                                showValidateIcon={meta.showValidateIcon}
                />

        );
    });

    render() {
        return this.renderFields();
    }

}

ValidatedForm.propTypes = {
    fields: PropTypes.oneOfType([
        PropTypes.objectOf(ValidatedField),
        PropTypes.objectOf(ValidatedLabel)]
    ),
    onValidChange: PropTypes.func,
    onBlur: PropTypes.func,
    handleFieldValueChange: PropTypes.func,
    isValidOnStart: PropTypes.bool
};

ValidatedForm.defaultProps = {
    fields: {},
    isValidOnStart: true,
    onBlur: () => {}
};

export default ValidatedForm;
